
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useCallback } from 'react';
import { ThemeProvider } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import { client } from 'helpers/apollo';
import { defaultTheme, defaultDesktopTheme } from 'assets/theme/theme';
import useSetupLocale from 'state/useSetupLocale';
import AuthProvider from 'shared/providers/AuthProvider';
import ModalsGlobal from 'library/molecules/ModalsGlobal';
import GlobalStyle from 'assets/theme/GlobalStyle';
import 'intl-pluralrules'; // Intl.PluralRules fix for older versions of Safari - #4011
import BasketProvider from 'providers/BasketProvider';
import PageLoadedSignifier from 'shared/library/atoms/PageLoadedSignifier';
import dynamic from 'next/dynamic';
import { AppProps } from 'next/dist/shared/lib/router/router';
import PageRouterProvider from 'shared/providers/PageRouterProvider';
import ShouldAccess from 'library/molecules/ShouldAccess';
import { PROJECT } from 'enums/project';
import { MotionGlobalConfig } from 'framer-motion';
import { useState, useEffect } from 'react';
import { ON_SERVER } from 'shared/enums/environment';
import { PrismicPreview } from '@prismicio/next';
import { repositoryName } from '../prismicio';
const useMediaQuery = (width: number) => {
    // No point adding event listeners on server (it only renders once)
    // Eslint complains that this is a conditional hook render but it's not
    // because the subsequent hooks _never_ run on the server.
    if (ON_SERVER)
        return false; // default to narrow (mobile) on server
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [targetReached, setTargetReached] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const updateTarget = useCallback((e: MediaQueryListEvent) => {
        if (e.matches) {
            setTargetReached(true);
        }
        else {
            setTargetReached(false);
        }
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const media = window.matchMedia(`(min-width: ${width}px)`);
        media.addEventListener('change', updateTarget);
        if (media.matches) {
            setTargetReached(true);
        }
        return () => media.removeEventListener('change', updateTarget);
    }, []);
    return targetReached;
};
if (process.env.NODE_ENV === 'test' ||
    process.env.DISABLE_ANIMATIONS === 'true') {
    MotionGlobalConfig.skipAnimations = true;
}
const PageTracker = dynamic(() => import('library/molecules/PageTracker'));
function EolaApp({ Component, pageProps, err }: AppProps) {
    useSetupLocale();
    const wide = useMediaQuery(defaultTheme.breakpoints['lg']);
    return (<>
      <ApolloProvider client={client}>
        <ThemeProvider theme={wide ? defaultDesktopTheme : defaultTheme}>
          <AuthProvider project={PROJECT}>
            <BasketProvider>
              <PageLoadedSignifier />
              <GlobalStyle />
              <ModalsGlobal />
              <PageRouterProvider>
                <ShouldAccess>
                  <Component {...pageProps} err={err}/>
                  <PrismicPreview repositoryName={repositoryName}/>
                </ShouldAccess>
              </PageRouterProvider>
              <PageTracker />
            </BasketProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>);
}
const __Next_Translate__Page__1932f760454__ = EolaApp;

    export default __appWithI18n(__Next_Translate__Page__1932f760454__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  